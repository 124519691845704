import uuid from 'react-uuid';
import uniqolor from 'uniqolor';

import { getBaseUrl, getAPIVersion } from '../config';
import { Zone } from '../models/Zone';

const version = getAPIVersion();
const path = `/api/Hunts/`;

//#region HELPERS
export function fetchNewZone() {
  const zoneId = uuid();
  const shortName = '';
  const longName = '';
  const description = '';
  const latitude = 0;
  const longitude = 0;
  const radius = 20;
  const color = uniqolor.random();
  const parkId = uuid();
  const isActive = true;

  return new Zone(
    zoneId,
    shortName,
    longName,
    description,
    latitude,
    longitude,
    radius,
    color.color,
    parkId,
    isActive
  );
}
//#endregion

//#region API CALLS
export async function getZones(context, parkId) {
  const response = await fetch(getBaseUrl() + path + `gethunts?ParkId=${parkId}&InactiveVisible=true`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    }
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function getZone(context, ParkId, ZoneId) {
  const response = await fetch(getBaseUrl() + path + `gethunt?ParkId=${ParkId}&huntId=${ZoneId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    }
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function addZone(context, data, parkId) {
  const response = await fetch(getBaseUrl() + path + 'addhunt', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
      shortName: data.ShortName,
      longName: data.LongName,
      description: data.Description,
      latitude: data.Latitude,
      longitude: data.Longitude,
      radius: data.Radius,
      color: data.Color,
      sequenceType: 1
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function setZoneStatus(context, parkId, zoneId, isActive) {
  const response = await fetch(getBaseUrl() + path + 'sethuntactive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId,
      HuntId: zoneId,
      isActive,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function updateZone(context, data, ParkId, ZoneId) {
  const response = await fetch(getBaseUrl() + path + 'updatehunt', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: ParkId,
      HuntId: data.HuntId,
      ShortName: data.ShortName,
      LongName: data.LongName,
      Description: data.Description,
      Latitude: data.Latitude,
      Longitude: data.Longitude,
      Radius: data.Radius,
      Color: data.Color,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function deleteZone(context, ParkId, ZoneId) {
  const response = await fetch(getBaseUrl() + path + 'deletehunt', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: ParkId,
      huntId: ZoneId,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function updateZoneStatus(context, parkId, zoneId, isActive) {
  const response = await fetch(getBaseUrl() + path + 'sethuntactive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId,
      HuntId: zoneId,
      isActive,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

//@To do verify endpoint to send data
export async function  updateHuntSequence(context, data, parkId) {
  const huntSequence = data.map((item, index) => ({
    huntId: item.ZoneId,
    sequence: index
  }));

  const response = await fetch(getBaseUrl() +  '/api/Hunts/updatehuntsequence', {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
      huntIdSequencePairRequests: huntSequence
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
//#endregion
