class Organization {
  Id: number;
  OrgId: number;
  Name: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  ContactEmail: string;
  ContactPhoneNumber: string;

  constructor(
    id: number,
    orgId: number,
    name: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    contactEmail: string,
    contactPhoneNumber: string
  ) {
    this.Id = id;
    this.OrgId = orgId;
    this.Name = name;
    this.Address = address;
    this.City = city;
    this.State = state;
    this.Zip = zip;
    this.ContactEmail = contactEmail;
    this.ContactPhoneNumber = contactPhoneNumber;
  }
}

export default Organization;
